// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-sl-about-js": () => import("./../../../src/pages-sl/about.js" /* webpackChunkName: "component---src-pages-sl-about-js" */),
  "component---src-pages-sl-calculator-js": () => import("./../../../src/pages-sl/calculator.js" /* webpackChunkName: "component---src-pages-sl-calculator-js" */),
  "component---src-pages-sl-calculator-result-js": () => import("./../../../src/pages-sl/calculator-result.js" /* webpackChunkName: "component---src-pages-sl-calculator-result-js" */),
  "component---src-pages-sl-careers-js": () => import("./../../../src/pages-sl/careers.js" /* webpackChunkName: "component---src-pages-sl-careers-js" */),
  "component---src-pages-sl-confirmation-js": () => import("./../../../src/pages-sl/confirmation.js" /* webpackChunkName: "component---src-pages-sl-confirmation-js" */),
  "component---src-pages-sl-contact-js": () => import("./../../../src/pages-sl/contact.js" /* webpackChunkName: "component---src-pages-sl-contact-js" */),
  "component---src-pages-sl-download-js": () => import("./../../../src/pages-sl/download.js" /* webpackChunkName: "component---src-pages-sl-download-js" */),
  "component---src-pages-sl-index-js": () => import("./../../../src/pages-sl/index.js" /* webpackChunkName: "component---src-pages-sl-index-js" */),
  "component---src-pages-sl-legal-js": () => import("./../../../src/pages-sl/legal.js" /* webpackChunkName: "component---src-pages-sl-legal-js" */),
  "component---src-pages-sl-our-services-js": () => import("./../../../src/pages-sl/our-services.js" /* webpackChunkName: "component---src-pages-sl-our-services-js" */),
  "component---src-pages-sl-our-services-new-calculator-js": () => import("./../../../src/pages-sl/our-services/new-calculator.js" /* webpackChunkName: "component---src-pages-sl-our-services-new-calculator-js" */),
  "component---src-pages-sl-signup-js": () => import("./../../../src/pages-sl/signup.js" /* webpackChunkName: "component---src-pages-sl-signup-js" */),
  "component---src-pages-sl-supplier-js": () => import("./../../../src/pages-sl/supplier.js" /* webpackChunkName: "component---src-pages-sl-supplier-js" */),
  "component---src-templates-case-page-js": () => import("./../../../src/templates/case-page.js" /* webpackChunkName: "component---src-templates-case-page-js" */),
  "component---src-templates-job-posting-js": () => import("./../../../src/templates/job-posting.js" /* webpackChunkName: "component---src-templates-job-posting-js" */)
}

